module.exports = [
  {
    image: 'images/template_1.gif',
    lines: [
      { x: 120, y: 920, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1045, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1095, width: 600, fontFamily: 'Itim', fontSize: 28 },
    ],
  },
  {
    image: 'images/template_2.gif',
    lines: [
      { x: 120, y: 910, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1035, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1085, width: 600, fontFamily: 'Itim', fontSize: 28 },
    ],
  },
  {
    image: 'images/template_3.gif',
    lines: [
      { x: 120, y: 920, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1045, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1095, width: 600, fontFamily: 'Itim', fontSize: 28 },
    ],
  },
  {
    image: 'images/template_4.gif',
    lines: [
      { x: 120, y: 920, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1045, width: 600, fontFamily: 'Itim', fontSize: 28 },
      { x: 120, y: 1095, width: 600, fontFamily: 'Itim', fontSize: 28 },
    ],
  },
];
