import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import Preview from '../components/Preview';
import Share from '../components/Share';
import settings from '../settings';

const useStyles = makeStyles((theme) => ({
  contentGrid: {
    padding: '0px 16px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  instruction: {
    backgroundColor: '#EEE',
    border: 'solid 1px #CCC',
    borderRadius: '4px',
    margin: '8px 0px',
    padding: '12px 16px',
    color: '#999',
  },
  instructionHead: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 8
  },
  instructionText: {
    fontSize: 12,
  },
  previewGrid: {
    width: '100%',
  },
  smallImage: {
    backgroundImage: ({ image }) => `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    flex: 1,
    height: 150,
    margin: '0px 8px',
  },
  textField: {
    margin: '8px 0px',
  },
}));

const initialMessages = {
  recipient: '',
  message1: '',
  message2: '',
};

const Home = () => {
  const [messages, setMessages] = useState(initialMessages);
  const [selected, setSelected] = useState(0);
  const [shareUrl, setShareUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [snackBar, setSnackBar] = useState();
  const {
    contentGrid,
    instruction,
    instructionHead,
    instructionText,
    previewGrid,
    textField,
  } = useStyles();

  const handleChange = (e) => {
    const newMessages = { ...messages, [e.target.id]: e.target.value };
    setMessages(newMessages);
  };
  const handleClick = async () => {
    setLoading(true);
    setSnackBar('กรุณารอสักครู่ เรากำลังสร้างการ์ดของคุณ');
    const data = JSON.stringify({
      template: selected,
      messages: [messages.recipient, messages.message1, messages.message2],
    });
    try {
      const response = await axios.post(
        'https://us-central1-progaming-ecard-dra.cloudfunctions.net/create',
        // 'http://localhost:5001/progaming-ecard-dra/us-central1/create',
        data,
        { headers: { 'Content-Type': 'application/json' } }
      );
      const storagePath = 'https://storage.googleapis.com/ecard-dra';
      const fullPath = `${storagePath}/${response.data}`;
      setShareUrl(fullPath);
      setSnackBar();
      setLoading(false);
      // log template used
      window.gtag('config', 'G-CL84N5G4E7', {
        page_title: `template-${selected+1}`,
      });
    } catch (e) {
      setSnackBar('มีข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
      setLoading(false);
    }
  };
  const handleReset = () => {
    setMessages(initialMessages);
    setSelected(0);
    setShareUrl();
  };

  const renderEdit = () => {
    return (
      <>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Choice
            image={settings[0].image}
            onClick={() => !loading && setSelected(0)}
          />
          <Choice
            image={settings[1].image}
            onClick={() => !loading && setSelected(1)}
          />
          <Choice
            image={settings[2].image}
            onClick={() => !loading && setSelected(2)}
          />
          <Choice
            image={settings[3].image}
            onClick={() => !loading && setSelected(3)}
          />
        </Box>
        <Box>
          <TextField
            id="recipient"
            className={textField}
            variant="outlined"
            fullWidth
            label="ชื่อผู้รับ"
            helperText="ความยาวไม่เกิน 50 ตัวอักษร"
            inputProps={{
              maxLength: 50,
            }}
            disabled={loading}
            onChange={handleChange}
          />
          <TextField
            id="message1"
            className={textField}
            variant="outlined"
            fullWidth
            label="บรรทัดที่ 1"
            helperText="ความยาวไม่เกิน 75 ตัวอักษร"
            inputProps={{
              maxLength: 75,
            }}
            disabled={loading}
            onChange={handleChange}
          />
          <TextField
            id="message2"
            className={textField}
            variant="outlined"
            fullWidth
            label="บรรทัดที่ 2"
            helperText="ความยาวไม่เกิน 75 ตัวอักษร"
            inputProps={{
              maxLength: 75,
            }}
            disabled={loading}
            onChange={handleChange}
          />
        </Box>
        <div className={instruction}>
          <Typography className={instructionHead}>วิธีใช้งาน</Typography>
          <Typography className={instructionText}>
            1. เลือกการ์ดที่คุณชอบ
          </Typography>
          <Typography className={instructionText}>
            2. กรอกชื่อผู้รับ และข้อความดีๆ
          </Typography>
          <Typography className={instructionText}>
            3. กดปุ่ม "สร้างการ์ด"
          </Typography>
          <Typography className={instructionText}>
            4. แชร์หรือดาวน์โหลดการ์ดของคุณ
          </Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={loading}
          onClick={handleClick}
        >
          สร้างการ์ด
        </Button>
      </>
    );
  };
  return (
    <Container maxWidth="md">
      <Grid container>
        <Grid item className={previewGrid} md={6}>
          <Preview
            setting={settings[selected]}
            messages={[
              messages.recipient,
              messages.message1,
              messages.message2,
            ]}
          />
        </Grid>
        <Grid item className={contentGrid} md={6}>
          {shareUrl ? (
            <Share url={shareUrl} onReset={handleReset} />
          ) : (
            renderEdit()
          )}
        </Grid>
      </Grid>
      <Snackbar message={snackBar} open={snackBar} autoHideDuration={3000} />
    </Container>
  );
};

const Choice = ({ image, onClick }) => {
  const { smallImage } = useStyles({ image });
  return <div className={smallImage} onClick={onClick} />;
};

export default Home;
