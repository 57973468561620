import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {},
  image: {
    objectFit: 'cover',
    width: '100%',
  },
});

const Header = () => {
  const { image, root } = useStyles();
  return (
    <div className={root}>
      <img className={image} src="images/header.png" alt="" />
    </div>
  );
};

export default Header;
