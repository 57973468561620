import React from 'react';
import { Box, Button, Icon, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Email, Save } from '@material-ui/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

const useStyles = makeStyles({
  button: {
    margin: '8px 0px',
  },
  icon: {
    marginRight: 8
  }
});

const buttonClasses =
  'MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-fullWidth';

const Share = ({ url, onReset }) => {
  const { button, icon } = useStyles();
  return (
    <Paper>
      <Box p={2} bgcolor="#6969ED" color="white"><Typography variant="h6">การ์ดความสุขของคุณพร้อมแล้ว!</Typography></Box>
      <Box p={1}>
        <FacebookShareButton
          className={`${buttonClasses} ${button}`}
          resetButtonStyle={false}
          url={url}
          quote="ทำดีเข้าพรรษา ชีวาเป็นสุข"
        >
          <Icon className={`${icon} fab fa-facebook-f`} fontSize="small" /> แชร์ไปยัง Facebook
        </FacebookShareButton>
        <TwitterShareButton
          className={`${buttonClasses} ${button}`}
          resetButtonStyle={false}
          url={url}
          title="ทำดีเข้าพรรษา ชีวาเป็นสุข"
        >
          <Icon className={`${icon} fab fa-twitter`} fontSize="small" /> แชร์ไปยัง Twitter
        </TwitterShareButton>
        <EmailShareButton
          className={`${buttonClasses} ${button}`}
          resetButtonStyle={false}
          url={url}
          subject="ทำดีเข้าพรรษา ชีวาเป็นสุข"
        >
          <Email className={icon} fontSize="small" /> ส่งอีเมล
        </EmailShareButton>
        <Button
          className={button}
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<Save />}
          onClick={() => window.open(url)}
        >
          ดาวน์โหลด
        </Button>
        <Button
          className={button}
          variant="contained"
          color="secondary"
          fullWidth
          onClick={onReset}
        >
          สร้างการ์ดใหม่
        </Button>
      </Box>
    </Paper>
  );
};

export default Share;
